import type { WidgetConfig } from '../../../types/frapi-types.js';

export const desktopWidgetConfig: WidgetConfig[] = [
  { widget: 'guides', position: 3 },
  { widget: 'reels', position: 1 },
  // { widget: 'restaurants', position: 3 },
];

export const mobileWidgetConfig: WidgetConfig[] = [
  { widget: 'guides', position: 3 },
  { widget: 'reels', position: 1 },
];
