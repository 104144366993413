import type {
  AdComponent,
  Component,
} from '../../../../core/data-sources/content-components/types.js';

import { getPlacements } from '../../../ads-config/placements.js';

enum Intention {
  groupStart = 'group-start',
  groupEnd = 'group-end',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isAdComponent = (object: any): object is AdComponent => {
  return (object as AdComponent)?.type === 'ad';
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isArticleComponent = (object: any): object is Component => {
  return !!(object as AdComponent)?.presentationProperties;
};
const insertAdAtIndex = <T>(
  elem: AdComponent,
  insertAt: number,
  array: T[],
): T[] => {
  const adsIsLast = isAdComponent(array.at(-1));
  const stackedAdsOnLastPlacements = insertAt >= array.length && adsIsLast;
  if (stackedAdsOnLastPlacements) {
    return array;
  }

  return [
    ...array.slice(0, insertAt),
    elem as unknown as T,
    ...array.slice(insertAt),
  ];
};

const groupBySeparateParagraphs = <T>(arrayWithPageElements: T[]): T[][] => {
  let addToExistingGroup = false;

  const groupedParagraphs: T[][] = [];

  Object.values(arrayWithPageElements).map((item) => {
    const intention =
      isArticleComponent(item) && item.presentationProperties?.intention;

    if (addToExistingGroup) {
      const lastParagraph = groupedParagraphs.at(-1);

      if (lastParagraph) {
        lastParagraph.push(item);
      }
    } else {
      groupedParagraphs.push([item]);
    }

    if (intention === Intention.groupStart) {
      addToExistingGroup = true;
    }

    if (intention === Intention.groupEnd) {
      addToExistingGroup = false;
    }
  });

  return groupedParagraphs;
};

const insertAdsPlacements = <T>(
  isMobile: boolean,
  arrayWithPageElements: T[],
  pageType: 'frontpage' | 'article',
  section: string,
): (AdComponent | T)[] => {
  const device = isMobile ? 'mobile' : 'desktop';
  const currentSectionPlacements = getPlacements(section);
  const placements = currentSectionPlacements[pageType][device];

  const pageElementsByParagraphs = groupBySeparateParagraphs(
    arrayWithPageElements,
  );

  const arrayWithAds = placements.reduce(
    (arrayWithAds, { id, sizes, position }) => {
      if (position) {
        return insertAdAtIndex(
          {
            id,
            type: 'ad',
            cssClass: `ad-${pageType}`,
            sizes,
            position,
          },
          position,
          arrayWithAds,
        );
      }

      return arrayWithAds;
    },
    pageElementsByParagraphs,
  );

  return arrayWithAds.flat();
};

export { insertAdAtIndex, insertAdsPlacements };
