import React, { useEffect, useState } from 'react';

import {
  getLoginUrl,
  loginAction,
} from '../../../../../public-src/core/js/schibsted-account/index.server.js';
import { useSessionData } from '../../../../../public-src/core/js/schibsted-account/react.server.js';
import { Overlay } from './Overlay.js';
import { frontendConfig } from '../../../../core/system/config/frontend.server.js';

interface LoginWallProps {
  children?: React.ReactNode;
  handleOverlay?: () => void;
}
export const LoginWall: React.FC<LoginWallProps> = ({
  children,
  handleOverlay,
}) => {
  const [overlayVisible, setOverlayVisible] = useState(true);

  useEffect(() => {
    const verticalscroll = window.scrollY;
    document.documentElement.style.position = 'fixed';
    document.body.style.top = `-${verticalscroll}px`;

    return () => {
      const scrollY = document.body.style.top;
      document.documentElement.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    };
  }, []);

  const session = useSessionData();
  const handleLogin = (event: React.MouseEvent) => {
    event.preventDefault();

    loginAction({ state: `vink-${window.location.href}` });
  };

  const handleHide = () => {
    setOverlayVisible(false);
    handleOverlay && handleOverlay();
  };

  if (session !== false) {
    return <>{children}</>;
  }
  if (overlayVisible) {
    return (
      <Overlay className="dark-login">
        <button className="close-overlay" onClick={handleHide}>
          X
        </button>
        <a href="/" className="overlay-logo">
          <img
            src={`${frontendConfig.cdnUrlInternal}/vink/images/logo.svg`}
            alt="vink"
          />
        </a>
        <div className="nudge">Logg inn og lagre innhold på Mine Vink</div>
        <a className="action-button" href={getLoginUrl()} onClick={handleLogin}>
          Logg inn
        </a>
      </Overlay>
    );
  }

  return <></>;
};
