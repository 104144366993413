import React, { useEffect, useState } from 'react';

import type { SessionData } from '../../../../../../public-src/core/js/schibsted-account/types.js';

import { useSessionData } from '../../../../../../public-src/core/js/schibsted-account/react.server.js';
import { LinkWithPulse } from '../../../../../core/mandatory-integrations/pulse/components/LinkWithPulse.js';
import { LoginWall } from '../LoginWall.js';
import DropDownButton from '../DropDownButton.js';
import NavigationMenu from './NavigationMenu.js';
import { useOutsideClick } from '../../../../../core/react/hooks/index.js';
import { frontendConfig } from '../../../../../core/system/config/frontend.server.js';
import NavigationSections from './NavigationSections.js';
import SubscribeBar from './SubscribeBar.js';

interface NavigationProps {
  fullWidth: boolean;
  activeSection?: string;
  isTransparent?: boolean;
  isSticky?: boolean;
  isHermesApp?: boolean;
}

const { cdnUrlInternal } = frontendConfig;

const SubscribeButton = () => (
  <LinkWithPulse
    className="subscribe fade-in"
    href="/bli-abonnent"
    id="navigation:subscribe-button"
    name="Subscribe-button navigation header"
  >
    Bli abonnent
  </LinkWithPulse>
);

const SearchButton = () => (
  <LinkWithPulse
    className="search"
    href="/sok"
    id="navigation:search-button"
    name="Search button in navigation header"
  >
    <img src={`${cdnUrlInternal}/vink/images/header/v2/search.svg`} alt="søk" />
  </LinkWithPulse>
);

const BookmarkButton = ({ session }: { session: SessionData }) => {
  if (session) {
    return (
      <LinkWithPulse
        className="admin bookmark"
        href="/mine-vink"
        id="navigation:mine-vink"
        name="Mine vink button in navigation header"
      >
        <img
          src={`${cdnUrlInternal}/vink/images/icons/bookmark-bold.svg`}
          alt="mine vink"
        />
      </LinkWithPulse>
    );
  }

  return <></>;
};

const HomeButton = () => (
  <LinkWithPulse
    href="/"
    className="logo"
    id="navigation:logo-button"
    name="Logo redirect to home in navigation header"
  >
    <img src={`${cdnUrlInternal}/vink/images/logo.svg`} alt="vink" />
  </LinkWithPulse>
);

const Navigation: React.FC<NavigationProps> = ({
  isTransparent,
  isSticky,
  isHermesApp = true,
  activeSection,
  fullWidth,
}) => {
  const session = useSessionData();
  const [showSubscribeButton, setShowSubscribeButton] = useState(false);
  const [controlSaves, setControlSaves] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [showTcf, setShowTcf] = useState(false);
  const handleOutsideClick = () => {
    setOpenMenu(false);
  };

  useEffect(() => {
    if (session === null) {
      // session not initialized yet
      return;
    }

    setShowSubscribeButton(
      !isHermesApp && (!session || !session?.access.entitled),
    );
  }, [isHermesApp, session]);

  useEffect(() => {
    if (!window._tcf_?.showPrivacyManager) {
      setShowTcf(false);
    } else {
      setShowTcf(true);
    }
  }, []);

  const containerRef = useOutsideClick(handleOutsideClick);

  const handleControlSaves = () => {
    setControlSaves(!controlSaves);
  };

  const classNames = [
    'navigation',
    'advertory-navigation-anchor',
    isTransparent && 'transparent',
    isSticky && 'sticky',
    activeSection && activeSection,
  ].join(' ');

  return (
    <div className={classNames}>
      {fullWidth && showSubscribeButton && <SubscribeBar />}
      <div className="container">
        <HomeButton />
        {!fullWidth && showSubscribeButton && <SubscribeButton />}
        {fullWidth && <NavigationSections activeSection={activeSection} />}
        <div className="admin-container">
          <BookmarkButton session={session} />
          <SearchButton />
          <div className="menu-wrapper" ref={containerRef}>
            <DropDownButton
              onClick={() => setOpenMenu(!openMenu)}
              active={openMenu}
            />
            {openMenu && (
              <NavigationMenu
                hasSession={!!session}
                isMobile={!fullWidth}
                isHermesApp={isHermesApp}
                activeSection={activeSection}
                showTcf={showTcf}
              />
            )}
          </div>
        </div>
      </div>
      {controlSaves && <LoginWall handleOverlay={handleControlSaves} />}
    </div>
  );
};

export default Navigation;
