import { ApReels, VinkReels } from '@schibsted-regional/components';
import React from 'react';

import type { ApReelsProps } from '@schibsted-regional/components';
import type { SvpAsset } from '../../../core/bindings/data/fetch-stream-data.js';

import {
  usePulseContext,
  withPulse,
} from '../../../core/mandatory-integrations/pulse/PulseProvider.js';
import { isMobileApp } from '../../../core/utils/is-mobile.js';
import { useReelsOverlay } from '../../../regional/rendering/blocks/Reels/useReelsOverlay.js';

type Props = {
  title: string;
  playlistId: string;
  reels: SvpAsset[];
  url: string;
  brand: 'vink' | 'ap';
};

const Reels = ({ title, reels, playlistId, url, brand }: Props) => {
  const { trackClickUIElement } = usePulseContext();

  const preloadUrls = reels.map(
    ({ id }) => `${url}/stories/${id}?playlistId=${playlistId}`,
  );

  const previews = reels.map((video) => {
    const { metadata } = video.additional;

    return {
      id: video.id,
      title: video.title,
      link: `${url}/stories/${video.id}?playlistId=${playlistId}`,
      duration: Number(video.duration),
      imagePreviewUrl: video.images.main,
      videoPreviewUrl: `${
        metadata?.preview_vivi_category ||
        metadata?.preview_360p ||
        metadata?.preview ||
        metadata?.preview_hq
      }`,
    };
  });

  const { openReel } = useReelsOverlay({ preloadItems: preloadUrls });

  const onPreviewClick: ApReelsProps['onPreviewClick'] = (event, video) => {
    const useIframeOverlay = !isMobileApp();

    trackClickUIElement({
      object: {
        id: `reel-${video.id}`,
        name: `Reels with video id ${video.id} in playlist ${playlistId}`,
        elementType: 'Button',
      },
    });

    if (useIframeOverlay) {
      event.preventDefault();
      openReel(video.link);
    }
  };

  const commonProps: ApReelsProps = { title, previews, onPreviewClick };

  const reelsCarousel = getBrandReels(brand, commonProps);

  return (
    <div
      data-pulse-entity-id={`${brand}-reels`}
      data-pulse-entity-name={`${brand} reels sliders`}
      data-pulse-entity-type="Reel"
      className="reels-widget"
    >
      {reelsCarousel}
    </div>
  );
};

const getBrandReels = (brand: 'vink' | 'ap', commonProps: ApReelsProps) => {
  if (brand === 'ap') {
    return <ApReels {...commonProps} />;
  }

  return <VinkReels {...commonProps} />;
};

const ReelsWithPulse = withPulse(Reels);

// Seems to be a bug with composing multiple HOC, so we need an extra wrapper before it goes into the hydration HOC.
export const ReelsSlider = (props: Props) => <ReelsWithPulse {...props} />;
