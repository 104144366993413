import type {
  TrackViewArticle,
  ViewArticleInputObject,
  ViewSalesPosterInputObject,
  TrackViewSalesPoster,
  BaseEventOrigin,
  TrackViewPage,
  TrackViewListing,
} from '@schibsted/pulse-news-media';
import type {
  ByfunnFrapiArticle,
  ByfunnPromoArticle,
} from '../types/frapi-types.js';
import type { CuratePulseTrackingCustomProperties } from '../../core/utils/types.js';

import { accessLevel, accessLevelName } from '../../core/utils/access.js';
import { getAuthorEmails } from '../views/components/articles/utils/authors.js';
import { generateMetrics } from '../../core/mandatory-integrations/pulse/utils/metrics.js';

const trackArticlePageView = (
  trackViewArticle: TrackViewArticle,
  article: ByfunnFrapiArticle,
) => {
  const imageUrl = article.mainImage
    ? `https://premium.vgc.no/ap/images/${article.mainImage.id}?fit=crop&h=878&q=80&tight=true&w=2048`
    : 'https://cdn.aftenposten.no/vink/images/wordmark.png';

  const object: ViewArticleInputObject = {
    articleId: article.id,
    accessLevel: accessLevel(article.access),
    accessLevelName: accessLevelName(article.access),
    authors: getAuthorEmails(article),
    category: article.section,
    contentId: article.id,
    name: article.title,
    tags: article.tags.map((t) => t.title),
    wordCount: article.wordCount,
    'spt:custom': {
      'spt:wordCount': article.wordCount,
      'spt:authors': getAuthorEmails(article),
      'spt:published': article.published,
      'spt:update': article.updated,
      'spt:imageUrl': article?.mainImage && imageUrl,
    },
  };

  trackViewArticle({ object });
};

const trackFrontpageView = (
  trackViewListing: TrackViewListing,
  personalization: CuratePulseTrackingCustomProperties,
) => {
  trackViewListing({
    object: {
      id: 'frontpage',
      name: 'Frontpage - Vink',
    },
    metrics: generateMetrics(personalization.variant, personalization),
  }).then((data) => data);
};

const trackSectionPageView = (
  trackViewListing: TrackViewListing,
  sectionName: string,
  personalization?: CuratePulseTrackingCustomProperties,
) => {
  if (!personalization) {
    trackViewListing({
      object: {
        id: 'section-page',
        name: sectionName,
      },
    }).then((data) => data);
  } else {
    trackViewListing({
      object: {
        id: 'section-page',
        name: sectionName,
      },
      metrics: generateMetrics(personalization.variant, personalization),
    }).then((data) => data);
  }
};

const trackStaticPageView = (
  trackViewPage: TrackViewPage,
  componentName: string,
  custom?: Record<string, unknown>,
) => {
  trackViewPage({
    object: {
      pageId: componentName === 'error' ? 'error:404' : componentName,
      name: `Page - ${componentName}`,
      'spt:custom': custom,
    },
  }).then((data) => data);
};

const trackSearchPageView = (
  trackViewListing: TrackViewListing,
  currentUrl: string,
) => {
  const query = currentUrl.match('phrase=(.*)&')?.[1] || currentUrl;

  trackViewListing({
    object: {
      id: 'search',
      name: 'Search Result Page',
      'spt:custom': {
        query,
      },
    },
  }).then((data) => data);
};

const trackTagPageView = (
  trackViewListing: TrackViewListing,
  currentUrl: string,
  tagName: string,
) => {
  trackViewListing({
    object: {
      id: 'tag',
      name: tagName,
      'spt:custom': {
        currentUrl,
      },
    },
  }).then((data) => data);
};

const trackStoryPageView = (
  trackViewListing: TrackViewListing,
  currentUrl: string,
  storyName: string,
) => {
  trackViewListing({
    object: {
      id: 'story',
      name: storyName,
      'spt:custom': {
        currentUrl,
      },
    },
  }).then((data) => data);
};

const trackSalesposterView = (
  trackViewSalesPoster: TrackViewSalesPoster,
  article: ByfunnPromoArticle,
  origin: BaseEventOrigin,
) => {
  const object: ViewSalesPosterInputObject = {
    articleId: article.id,
    name: article.title,
    salesPosterType: 'Paid',
    'spt:custom': {
      'spt:published': article.published,
      'spt:update': article.updated,
    },
  };

  trackViewSalesPoster({
    object,
    origin,
  });
};

export {
  trackArticlePageView,
  trackFrontpageView,
  trackSectionPageView,
  trackStaticPageView,
  trackSearchPageView,
  trackTagPageView,
  trackStoryPageView,
  trackSalesposterView,
};
